<template>
    <input
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.value)"
    >
</template>

<script>
export default {
    props: ['modelValue'],
    emits:['update:modelValue']
}
</script>

<style lang="scss" scoped>
@import '../assets/scss/var';

input {
    width: 100%;
    padding: 8px;
    border-radius: 4px;
    border: 1px solid $gray;
}
</style>
<template>
    <HeaderComponent />
    
    <main id="main" class="main">
        <router-view/>
    </main>

    <FooterComponent />
</template>

<script>
import HeaderComponent from '@/components/HeaderComponent.vue'
import FooterComponent from '@/components/FooterComponent.vue'

export default {
    name: 'App',
    components: {
        HeaderComponent,
        FooterComponent
    },
}
</script>

<style lang="scss">
@charset 'utf-8';

@import './assets/scss/reset';
@import './assets/scss/mixin';
@import './assets/scss/var';



html, body, input, textarea, select, button, pre {
    line-height: 1.75;
    font-family: "Yu Gothic", Meiryo, sans-serif;
}

a, i, p, div, span, dt, dd, th, td, li, a, label, input, textarea, button, select, address, small {
    font-size: 1.4rem;

    @include mq(){
        font-size: 1.6rem;
    }
}

.sp {
    display: block;

    @include mq(){
        display: none;
    }
}

.pc {
    display: none;

    @include mq(){
        display: block;
    }
}

.main {
    width: 100%;
    min-height: calc(100vh - 110px);
}



.btn {
    position: relative;
    width: auto;
    padding: 10px 40px 10px 20px;
    text-align: center;
    font-size: 1.2rem;
    font-weight: bold;
    transition: .3s;
    text-decoration: none;

    @include mq(){
        padding: 10px 60px 10px 40px;
        font-size: 1.4rem;
    }

    &:hover {
        opacity: .7;
    }

    &:before {
        content: "\f105";
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translateY(-50%);
        font-weight: 900;
        font-family: "Font Awesome 5 Free";
    }

    &__primary {
        color: $white;
        background-color: $primary;
    }
    
    &__primary-light {
        color: $white;
        background-color: $primary-light;
    }

    &__primary-dark {
        color: $white;
        background-color: $primary-dark;
    }

    &__red {
        color: $white;
        background-color: $red;
    }

    //プレビュー
    &__preview {
        position: absolute;
        top: 52px;
        right: 0;
        padding: 4px 10px;
        border-radius: 25px;
        color: $white;
        background-color: $red;

        @include mq(){
            top: 54px;
        }

        @include mq(xl){
            top: 8px;
            padding: 8px 24px;
        }

        &:before {
            content: '';
        }
    }

    //書類をダウンロードする
    &__download {
        font-size: 1.8rem;
        color: $white;
        background-color: $red;

        @include mq(){
            font-size: 2rem;
        }
    }
}
</style>

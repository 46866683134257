import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import Document from '../views/Document.vue'
import PrivacyPolicy from '../views/PrivacyPolicy.vue'
import Terms from '../views/Terms.vue'
import Contact from '../views/Contact.vue'

const descriptionText = '登録不要！無料で使える！「SPEEC」はビジネスに必要な見積書、発注書、請求書等の7種類の書類を作成することができる無料のWEBサービスです。'
const routes = [
    {
        //トップページ
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            title: '【登録不要/無料】WEB見積書作成サービス｜WEBで書類を作成するなら【SPEEC】',
            description: descriptionText
        }
    },
    {
        //書類作成
        path: '/document',
        name: 'Document',
        component: Document,
        meta: {
            title: '書類作成｜SPEEC',
            description: descriptionText
        }
    },
    {
        //個人情報保護方針
        path: '/privacy-policy',
        name: 'PrivacyPolicy',
        component: PrivacyPolicy,
        meta: {
            title: '個人情報保護方針｜SPEEC',
            description: descriptionText
        }
    },
    {
        //利用規約
        path: '/terms',
        name: 'Terms',
        component: Terms,
        meta: {
            title: '利用規約｜SPEEC',
            description: descriptionText
        }
    },
    {
        //お問い合わせ
        path: '/contact',
        name: 'Contact',
        component: Contact,
        meta: {
            title: 'お問い合わせ｜SPEEC',
            description: descriptionText
        }
    },
]

const router = createRouter({
    routes,
    history: createWebHistory(process.env.BASE_URL),
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return { top: 0 }
        }
    },
})

router.afterEach((to) => {
    //body要素のoverflowをautoに設定
    document.body.style.overflowY = 'auto'
    //title要素にmeta.titleを設定
    document.title = to.meta.title
    //meta:descriptionにmeta.descriptionを設定
    document.querySelector('meta[name="description"]').setAttribute('content', to.meta.description)
})

export default router

<template>
    <div class="document">
        <h1 class="document__title">{{ title }}</h1>
        <div class="document__header">
            <p class="document__header-text">「プレビュー」で表示されるPDFを確認しながら書類を作成します。書類に必要な情報を入力してください。</p>
        </div> 
        <div class="document__tab">
            <button class="btn btn__preview" v-on:click="preview">プレビュー</button>
            <ul class="document__tab-menu">
                <li class="document__tab-menu-item" v-on:click="isSelect('1')" v-bind:class="{'active': isActive === '1'}">あなたの情報</li>
                <li class="document__tab-menu-item" v-on:click="isSelect('2')" v-bind:class="{'active': isActive === '2'}">口座情報</li>
                <li class="document__tab-menu-item" v-on:click="isSelect('3')" v-bind:class="{'active': isActive === '3'}">顧客情報</li>
                <li class="document__tab-menu-item" v-on:click="isSelect('4')" v-bind:class="{'active': isActive === '4'}">見積概要</li>
                <li class="document__tab-menu-item" v-on:click="isSelect('5')" v-bind:class="{'active': isActive === '5'}">見積内容</li>
            </ul>
            <div class="document__tab-content">
                <!-- あなたの情報 -->
                <div class="document__tab-content-item" v-if="isActive === '1'">
                    <div class="document__tab-content-item-text">
                        <p>書類に記載するあなたの情報を入力してください。</p>
                    </div>
                    <table class="document__table-info">
                        <tbody>
                            <tr>
                                <th>お名前(会社名)</th>
                                <td><InputComponent v-model="doc.user.name" placeholder="例)株式会社〇〇〇〇" /></td>
                            </tr>
                            <tr>
                                <th>郵便番号</th>
                                <td><InputComponent v-model="doc.user.zipcode" placeholder="例)123-4567" /></td>
                            </tr>
                            <tr>
                                <th>住所</th>
                                <td><InputComponent v-model="doc.user.address1" placeholder="例)〇〇県〇〇市〇〇区〇〇町１－２－３" /></td>
                            </tr>
                            <tr>
                                <th>建物名・号室</th>
                                <td><InputComponent v-model="doc.user.address2" placeholder="例)〇〇〇〇ビル５F" /></td>
                            </tr>
                            <tr>
                                <th>電話番号</th>
                                <td><InputComponent v-model="doc.user.tel" placeholder="例)0123-456-789" /></td>
                            </tr>
                            <tr>
                                <th>メールアドレス</th>
                                <td><InputComponent v-model="doc.user.email" placeholder="例)info@speec.jp" /></td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <!-- 口座情報 -->
                <div class="document__tab-content-item" v-if="isActive === '2'">
                    <div class="document__tab-content-item-text">
                        <p>あなたの口座情報を入力してください。口座情報は請求書の振込先に記載されます。</p>
                    </div>
                    <table class="document__table-info">
                        <tbody>
                            <tr>
                                <th>銀行名</th>
                                <td><InputComponent v-model="doc.invoice.bank.name" placeholder="例)〇〇〇〇銀行" /></td>
                            </tr>
                            <tr>
                                <th>支店名</th>
                                <td><InputComponent v-model="doc.invoice.bank.branch" placeholder="例)〇〇〇〇支店" /></td>
                            </tr>
                            <tr>
                                <th>口座番号</th>
                                <td><InputComponent v-model="doc.invoice.bank.number" placeholder="例)1234567" /></td>
                            </tr>
                            <tr>
                                <th>口座種類</th>
                                <td>
                                    <div class="document__table-data-flex">
                                        <div class="radio">
                                            <input type="radio" id="bank-type1" class="radio-input" value="普通" v-model="doc.invoice.bank.type">
                                            <label for="bank-type1" class="radio-label">普通</label>
                                        </div>
                                        <div class="radio">
                                            <input type="radio" id="bank-type2" class="radio-input" value="定期" v-model="doc.invoice.bank.type">
                                            <label for="bank-type2" class="radio-label">定期</label>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <th>口座名義</th>
                                <td><InputComponent v-model="doc.invoice.bank.contractor" placeholder="例)タナカタロウ" /></td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <!-- 顧客情報 -->
                <div class="document__tab-content-item" v-if="isActive === '3'">
                    <div class="document__tab-content-item-text">
                        <p>書類を提出するお客様の情報を入力してください。</p>
                    </div>
                    <table class="document__table-info">
                        <tbody>
                            <tr>
                                <th>お名前(会社名)</th>
                                <td><InputComponent v-model="doc.client.name" placeholder="例)株式会社〇〇〇〇" /></td>
                            </tr>
                            <tr>
                                <th>郵便番号</th>
                                <td><InputComponent v-model="doc.client.zipcode" placeholder="例)123-4567" /></td>
                            </tr>
                            <tr>
                                <th>住所</th>
                                <td><InputComponent v-model="doc.client.address1" placeholder="例)〇〇県〇〇市〇〇区〇〇町１－２－３" /></td>
                            </tr>
                            <tr>
                                <th>建物名・号室</th>
                                <td><InputComponent v-model="doc.client.address2" placeholder="例)〇〇〇〇ビル５F" /></td>
                            </tr>
                            <tr>
                                <th>電話番号</th>
                                <td><InputComponent v-model="doc.client.tel" placeholder="例)0123-456-789" /></td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <!-- 見積概要 -->
                <div class="document__tab-content-item" v-if="isActive === '4'">
                    <div class="document__tab-content-item-text">
                        <p>案件の見積概要を入力してください。</p>
                    </div>
                    <table class="document__table-info">
                        <tbody>
                            <tr>
                                <th>見積日</th>
                                <td><InputComponent v-model="doc.matter.date" placeholder="例)20XX年XX月XX日" /></td>
                            </tr>
                            <tr>
                                <th>見積番号</th>
                                <td><InputComponent v-model="doc.matter.number" placeholder="例)M123456" /></td>
                            </tr>
                            <tr>
                                <th>見積件名</th>
                                <td><InputComponent v-model="doc.matter.subject" placeholder="例)ウェブサイト制作" /></td>
                            </tr>
                            <tr>
                                <th>支払条件</th>
                                <td><InputComponent v-model="doc.matter.payment" placeholder="例)月末締翌月末払" /></td>
                            </tr>
                            <tr>
                                <th>有効期限</th>
                                <td><InputComponent v-model="doc.matter.expiry" placeholder="例)御見積日より1ヵ月" /></td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <!-- 見積内容 -->
                <div class="document__tab-content-item" v-else-if="isActive === '5'">
                    <div class="document__tab-content-item-text">
                        <p>案件の見積内容を入力してください。</p>
                    </div>
                    <div class="document__table-wrap">
                        <table class="document__table-quotation">
                            <colgroup>
                                <col style="width: 45%;">
                                <col style="width: 10%;">
                                <col style="width: 10%;">
                                <col style="width: 15%;">
                                <col style="width: 20%;">
                            </colgroup>
                            <thead>
                                <tr>
                                    <th>摘要</th>
                                    <th>数量</th>
                                    <th>単位</th>
                                    <th>単価</th>
                                    <th>金額</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(row, index) in doc.quotation.rows" v-bind:key="row.id">
                                    <td><InputComponent v-model="row.description" /></td>
                                    <td><InputComponent v-model="row.num" /></td>
                                    <td><InputComponent v-model="row.unit" /></td>
                                    <td>
                                        <div class="document__table-data-flex">
                                            <InputComponent v-model="row.price" class="price" />
                                            <span class="price-unit">円</span>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="document__table-data-flex">
                                            <label class="amount">{{ amount(index, row.num, row.price) }}</label>
                                            <span class="amount-unit">円</span>
                                            <span class="delete" v-on:click="delRow(index)">削除</span>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <button class="btn btn__primary" v-on:click="addRow">行を追加する</button>
                    <table class="document__table-total">
                        <tbody>
                            <tr>
                                <th>小計</th>
                                <td>{{ subtotal.toLocaleString() }}円</td>
                            </tr>
                            <tr>
                                <th>消費税(10%)</th>
                                <td>{{ tax.toLocaleString() }}円</td>
                            </tr>
                            <tr>
                                <th>合計</th>
                                <td>{{ total.toLocaleString() }}円</td>
                            </tr>
                        </tbody>
                    </table>
                    
                    <table class="document__table-note">
                        <thead>
                            <tr>
                                <th>備考</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><textarea rows="5" v-model="doc.quotation.note"></textarea></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div class="document__footer">
            <p class="document__footer-text">プレビュー用の表示を外した書類<span>(PDF)</span>は下記の「書類をダウンロードする」から取得することができます。</p>
            <ul class="document__footer-list">
                <li>本ページで入力されたデータは保存されませんので、ご注意ください。</li>
            </ul>
        </div>
        <div class="document__action">
            <button class="btn btn__download" v-on:click="download">書類をダウンロードする</button>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import InputComponent from '@/components/InputComponent.vue'

export default {
    name: 'Document',
    components: {
        InputComponent,
        //StripeComponent,
    },
    data() {
        return {
            isActive: "1",
            open: false,
            title: '書類作成',
            doc: {
                //あなたの情報
                user: {
                    name: '', //お名前(会社名)
                    zipcode: '', //郵便番号
                    address1: '', //住所
                    address2: '', //建物・マンション名号室
                    tel: '', //電話番号
                    email: '', //メールアドレス
                },
                //顧客情報
                client: {
                    name: '', //お名前(会社名)
                    zipcode: '', //郵便番号
                    address1: '', //住所
                    address2: '', //建物・マンション名号室
                    tel: '', //電話番号
                },
                //見積概要
                matter: { 
                    number: '', //見積番号
                    date: '', //見積日
                    subject: '', //件名
                    payment: '', //支払条件
                    expiry: '', //有効期限
                },
                //請求情報
                invoice: { 
                    //date: '', //請求日
                    //limit: '', //支払期限
                    bank: {
                        name: '', //銀行名
                        branch: '', //支店名
                        number: '', //口座番号
                        type: '', //講座種類
                        contractor: '', //名義

                    },
                },
                //見積内容
                quotation: {
                    rows: [
                        {
                            description: '', //摘要
                            num: '', //数量
                            unit: '', //単位
                            price: '', //単価
                            amount: '', //金額
                        }
                    ],
                    tax: 0, //税率
                    subtotal: 0, //小計
                    total: 0, //合計
                    note: '', //備考
                },
            }
        }
    },
    mounted() {
        window.onbeforeunload = function () {
            return '行った変更が保存されない可能性があります。'
        }
        window.history.pushState(null, null, null)
        window.addEventListener("popstate", function() {
            window.history.pushState(null, null, null)
            return
        })
    },
    unmounted() {
        window.onbeforeunload = null
    },
    watch: {
        open: function(){
            document.body.style.overflowY = this.open ? 'hidden' : 'auto'
        },
    },
    computed: {
        subtotal: function() {
            return this.calcSubtotal()
        },
        tax: function() {
            return this.calcTax()
        },
        total: function() {
            return this.calcTotal()
        }
    },
    methods: {
        isSelect:function(num) {
            this.isActive = num
        },
        addRow:function(){
            this.doc.quotation.rows.push(
                {
                    description: '', //摘要
                    num: '', //数量
                    unit: '', //単位
                    price: '', //単価
                    amount : '', //金額
                }
            );
        },
        delRow: function(index){
            let rows = this.doc.quotation.rows
            rows.splice(index, 1)
        },
        calc: function(){
            let result = 0
            Array.from(this.doc.quotation.rows)
            .forEach(function(row){
                    let num = Number(row.num)
                    let price = Number(row.price)
                    if(Number.isInteger(num) && Number.isInteger(price)){
                        result += Number(num) * Number(price)
                    }
                }
            )
            return result
        },
        calcSubtotal: function(){
            let result = this.calc()
            this.doc.quotation.subtotal = result
            return result
        },
        calcTax: function(){
            let result = Math.round(this.calc() * 0.1)
            this.doc.quotation.tax = result
            return result
        },
        
        calcTotal: function(){
            let result = this.calc() + this.tax
            this.doc.quotation.total = result
            return result
        },
        amount: function(index, num, price){
            if(num === 0 || num === ''){
                num = 1
            }
            let result = 0
            result = (num * price) > 0 ? (num * price) : '　' 
            this.doc.quotation.rows[index].amount = result
            return result
        },
        preview: function(){
            const url = process.env.VUE_APP_PREVIEW_API_URL
            const params = {
                doc: this.doc
            }
            axios
                .post(url, params, { responseType: 'arraybuffer'})
                .then(function(response){
                    const fileURL = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf'}));
                    const fileLink = document.createElement('a');
                    fileLink.href = fileURL;
                    fileLink.setAttribute('target', 'preview.pdf');
                    document.body.appendChild(fileLink);
                    fileLink.click();
                })
                .catch(function(error){
                    console.log(error);
                });
        },
        download: function(){
            const url = process.env.VUE_APP_DOWNLOAD_API_URL
            const params = {
                doc: this.doc
            }
            axios
                .post(url, params, { responseType: 'arraybuffer'})
                .then(function(response){
                    const fileURL = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf'}));
                    const fileLink = document.createElement('a');
                    fileLink.href = fileURL;
                    fileLink.setAttribute('download', 'download.pdf');
                    document.body.appendChild(fileLink);
                    fileLink.click();
                })
                .catch(function(error){
                    console.log(error);
                });
        },
    }
}
</script>

<style lang="scss" scoped>
@import '../assets/scss/mixin';
@import '../assets/scss/var';

.document {
    width: 100%;
    max-width: 1080px;
    padding: 108px 20px 40px 20px;
    margin: 0 auto;

    @include mq(){
        padding: 148px 40px;
    }

    .required {
        &:after {
            content: '*';
            color: $red;
        }
    }

    &__title {
        margin: 0 0 20px 0;
        text-align: left;
        font-size: 2.4rem;
        font-weight: bold;

        @include mq(){
            font-size: 3.2rem;
        }
    }

    &__header {
        margin: 0 0 20px 0;

        &-text {
            margin: 0 0 20px 0;

            &:last-child {
                margin: 0;
            }

            span {
                font-size: 1rem;

                @include mq(){
                    font-size: 1.2rem;
                }
            }
        }
    }

    &__footer {
        margin: 0 0 20px 0;

        @include mq(){
            margin: 0 0 40px 0;
        }

        &-text {
            margin: 0 0 20px 0;

            &:last-child {
                margin: 0;
            }
            
            span {
                font-size: 1rem;

                @include mq(){
                    font-size: 1.2rem;
                }
            }
        }

        &-list {
            margin: -10px 0 0 0;
            list-style-type: none;

            li {
                position: relative;
                padding: 0 0 0 16px;
                font-size: 1.2rem;
                font-weight: bold;
                color: $gray-dark;

                &:before {
                    content: '※';
                    position: absolute;
                    top: 0;
                    left: 0;
                }

                @include mq(){
                    font-size: 1.4rem;
                }
            }
        }
    }

    &__tab {
        position: relative;
        margin: 0 0 10px 0;

        @include mq(){
            margin: 0 0 20px 0;
        }

        &-menu {
            display: flex;
            flex-wrap: wrap;
            list-style-type: none;

            &-item {
                width: 33.33%;
                padding: 10px 0;
                text-align: center;
                font-weight: bold;
                color: $white;
                background-color: $primary;
                cursor: pointer;

                @include mq(xl){
                    width: 150px;
                    padding: 16px 0;
                }

                &.active {
                    background-color: $primary-dark;
                }
            }
        }

        &-content {
            &-item {
                padding: 10px;
                border: 1px solid $gray;

                @include mq(){
                    padding: 20px;
                }

                &-text {
                    margin: 0 0 20px 0;
                }
            }
        }
    }

    &__table {
        &-data {
            &-flex {
                display: flex;
                align-items: center;
            }
        }

        &-wrap {
            overflow-y: auto;
        }
        
        &-info {
            display: table;
            width: 100%;
            border-collapse: collapse;

            tbody {
                tr {
                    th {
                        display: block;
                        width: 100%;
                        padding: 10px;
                        text-align: left;
                        font-weight: bold;
                        background-color: $gray-light;

                        @include mq(){
                            display: table-cell;
                            width: 25%;
                            padding: 20px;
                            vertical-align: top;
                            border-bottom: 1px solid $white;
                        }
                    }

                    td {
                        display: block;
                        width: 100%;
                        padding: 10px;
                        background-color: $white;

                        @include mq(){
                            display: table-cell;
                            width: 75%;
                            padding: 20px;
                            border-bottom: 1px solid $gray;
                        }

                        .radio {
                            display: flex;
                            align-items: center;
                            width: auto;
                            padding: 0 10px;
                            margin: 0 20px 0 0;
                            border-radius: 5px;
                            border: 1px solid $gray;

                            &-input {
                                width: auto;
                                margin: -1px 5px 0 0;
                            }

                            &-label {
                                display: block;
                                width: 60px;
                                text-align: left;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
        }

        &-quotation {
            display: table;
            width: 100%;
            min-width: 918px;
            border-collapse: collapse;

            thead {
                tr {
                    th {
                        display: table-cell;
                        padding: 10px;
                        text-align: left;
                        background-color: $gray-light;;
                        border-bottom: 1px solid $white;

                        @include mq(){
                            padding: 20px;
                        }
                    }
                }
            }

            tbody {
                tr {
                    td {
                        display: table-cell;
                        padding: 10px;
                        background-color: $white;
                        border-bottom: 1px solid $gray;

                        @include mq(){
                            padding: 20px;
                        }

                        .price {
                            width: 100%;

                            &-unit {
                                align-self: flex-end;
                            }
                        }

                        .amount {
                            display: block;
                            width: calc(100% - 70px);
                            padding: 8px;
                            border-radius: 4px;
                            border: 1px solid $gray;
                            background-color: $gray-light;

                            &-unit {
                                align-self: flex-end;
                            }
                        }

                        .delete {
                            display: block;
                            width: 40px;
                            margin: 0 0 0 auto;
                            padding: 4px 0;
                            text-align: center;
                            font-size: 1.2rem;
                            font-weight: bold;
                            color: $white;
                            background-color: $red;
                            border-radius: 25px;
                            cursor: pointer;
                        }

                        textarea {
                            width: 100%;
                            padding: 12px;
                            border-radius: 4px;
                            border: 1px solid $gray;
                        }
                    }
                }
            }
        }

        &-total {
            display: table;
            width: 100%;
            margin: 20px 0;
            border-collapse: collapse;

            @include mq(){
                width: 75%;
                margin: 0 0 40px auto;
            }

            @include mq(xl){
                width: 50%;
            }

            tbody {
                tr {
                    th {
                        display: table-cell;
                        width: 30%;
                        padding: 10px;
                        text-align: left;
                        vertical-align: top;
                        font-weight: bold;
                        background-color: $gray-light;
                        border-bottom: 1px solid $white;

                        @include mq(){
                            padding: 20px;
                        }
                    }

                    td {
                        display: table-cell;
                        width: 70%;
                        padding: 10px;
                        background-color: $white;
                        border-bottom: 1px solid $gray;

                        @include mq(){
                            padding: 20px;
                        }
                    }
                }
            }
        }

        &-note {
            display: table;
            width: 100%;
            border-collapse: collapse;

            thead {
                tr {
                    th {
                        display: table-cell;
                        width: 100%;
                        padding: 10px;
                        text-align: left;
                        background-color: $gray-light;
                        border-bottom: 1px solid $white;

                        @include mq(){
                            padding: 20px;
                        }
                    }
                }
            }

            tbody {
                tr {
                    td {
                        display: table-cell;
                        width: 100%;
                        padding: 10px;
                        background-color: $white;
                        border-bottom: 1px solid $gray;

                        @include mq(){
                            padding: 20px;
                        }

                        textarea {
                            width: 100%;
                            padding: 12px;
                            border-radius: 4px;
                            border: 1px solid $gray;
                        }
                    }
                }
            }
        }
    }

    &__action {
        display: flex;
        justify-content: center;
    }
}

.stripe {
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    padding: 10px;
    background-color: rgba(0, 0, 0, .5);
    z-index: 2;

    @include mq(){
        padding: 20px;
    }

    &__inner {
        position: relative;
        width: 100%;
        height: calc(100vh - 20px);
        max-width: 560px;
        overflow-y: auto;

        @include mq(){
            height: calc(100vh - 40px);
        }
    }

    &__close {
        position: absolute;
        top: 0;
        right: 0;
        display: block;
        width: 30px;
        height: 30px;
        line-height: 1;
        font-size: 3rem;
        color: $white;
        background-color: $primary-dark;
        cursor: pointer;

        @include mq(){
            width: 50px;
            height: 50px;
            font-size: 5rem;
        }
    }
}
</style>
<template>
    <footer id="footer" class="footer">
        <div class="footer__inner">
            <small class="footer__copyright">&copy;2022 SPEEC</small>
            <nav class="footer__nav">
                <router-link to="/privacy-policy">個人情報保護方針</router-link>
                <router-link to="/terms">利用規約</router-link>
                <router-link to="/contact">お問い合わせ</router-link>
            </nav>
        </div>
    </footer>
</template>

<style lang="scss">
@charset 'utf-8';

@import '../assets/scss/mixin';
@import '../assets/scss/var';

.footer {
    background-color: $gray-light;

    &__inner {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column-reverse;
        justify-content: center;
        padding: 20px;
        width: 100%;
        margin: 0 auto;

        @include mq(){
            flex-direction: row;
            justify-content: space-between;
        }
    }


    &__nav {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: 0 0 10px 0;

        @include mq(){
            margin: 0;
        }

        a {
            display: block;
            padding: 0 10px;
            margin: 0 0 10px 0;
            text-decoration: none;
            font-weight: bold;
            color: $black;

            @include mq(){
                padding: 0 10px;
                margin: 0;
            }
        }
    }

    &__copyright {
        display: block;
        width: 100;
        text-align: center;
        font-weight: bold;
    }
}
</style>
<template>
    <div class="terms">
        <h1 class="terms__title">{{ title }}</h1>
        <div class="terms__header">
            <p>本利用規約（以下「本規約」と言います。）には、本サービスの提供条件及び当方と利用ユーザーの皆様との間の権利義務関係が定められています。本サービスの利用に際しては、本規約の全文をお読みいただいたうえで、本規約に同意いただく必要があります。</p>
        </div> 
        <div class="terms__content">
            <!-- 第1条 -->
            <h2 class="terms__content-title">第1条（適用）</h2>
            <ol class="terms__content-list">
                <li>本規約は、本サービスの提供条件及び本サービスの利用に関する当方とユーザーとの間の権利義務関係を定めることを目的とし、ユーザーと当方との間の本サービスの利用に関わる一切の関係に適用されます。</li>
                <li>当方が当方ウェブサイト上で掲載する本サービス利用に関するルール（<router-link to="/terms">https://speec.jp/terms</router-link>）は、本規約の一部を構成するものとします。</li>
                <li>本規約の内容と、前項のルールその他の本規約外における本サービスの説明等とが異なる場合は、本規約の規定が優先して適用されるものとします。</li>
            </ol>

            <!-- 第2条 -->
            <h2 class="terms__content-title">第2条（定義）</h2>
            <p class="terms__content-text">本規約において使用する以下の用語は、各々以下に定める意味を有するものとします。</p>
            <ol class="terms__content-list">
                <li>「サービス利用契約」とは、本規約を契約条件として当方とユーザーの間で締結される、本サービスの利用契約を意味します。</li>
                <li>「知的財産権」とは、著作権、特許権、実用新案権、意匠権、商標権その他の知的財産権（それらの権利を取得し、またはそれらの権利につき登録等を出願する権利を含みます。）を意味します。</li>
                <li>「投稿データ」とは、ユーザーが本サービスを利用して投稿その他送信するコンテンツ（文章、画像、動画その他のデータを含みますがこれらに限りません。）を意味します。</li>
                <li>「当方」とは、本サービスを運営・管理する者を意味します。</li>
                <li>「当方ウェブサイト」とは、そのドメインが「speec.jp」である、当方が運営するウェブサイト（理由の如何を問わず、当方のウェブサイトのドメインまたは内容が変更された場合は、当該変更後のウェブサイトを含みます。）を意味します。</li>
                <li>「ユーザー」とは、本サービスを利用された個人または法人を意味します。</li>
                <li>「本サービス」とは、当方が提供する【SPEEC】という名称のサービス（理由の如何を問わずサービスの名称または内容が変更された場合は、当該変更後のサービスを含みます。）を意味します。</li>
            </ol>

            <!-- 第3条 -->
            <h2 class="terms__content-title">第3条（料金及び支払方法）</h2>
            <ol class="terms__content-list">
                <li>ユーザーは、本サービス利用の対価として、別途当方が定め、当方ウェブサイトに表示する利用料金を、当方が指定する支払方法により当方に支払うものとします。</li>
                <li>ユーザーが利用料金の支払を遅滞した場合、ユーザーは年14.6％の割合による遅延損害金を当方に支払うものとします。</li>
            </ol>

            <!-- 第4条 -->
            <h2 class="terms__content-title">第4条（禁止事項）</h2>
            <p class="terms__content-text">ユーザーは、本サービスの利用にあたり、以下の各号のいずれかに該当する行為または該当すると当方が判断する行為をしてはなりません。</p>
            <ol class="terms__content-list">
                <li>法令に違反する行為または犯罪行為に関連する行為</li>
                <li>当方、本サービスの他の利用者またはその他の第三者に対する詐欺または脅迫行為</li>
                <li>公序良俗に反する行為</li>
                <li>当方、本サービスの他の利用者またはその他の第三者の知的財産権、肖像権、プライバシーの権利、名誉、その他の権利または利益を侵害する行為</li>
                <li>本サービスを通じ、以下に該当し、または該当すると当方が判断する情報を当方または本サービスの他の利用者に送信すること
                    <ul class="terms__content-list">
                        <li>過度に暴力的または残虐な表現を含む情報</li>
                        <li>コンピューター・ウィルスその他の有害なコンピューター・プログラムを含む情報</li>
                        <li>当方、本サービスの他の利用者またはその他の第三者の名誉または信用を毀損する表現を含む情報</li>
                        <li>過度にわいせつな表現を含む情報</li>
                        <li>差別を助長する表現を含む情報</li>
                        <li>自殺、自傷行為を助長する表現を含む情報</li>
                        <li>薬物の不適切な利用を助長する表現を含む情報</li>
                        <li>反社会的な表現を含む情報</li>
                        <li>チェーンメール等の第三者への情報の拡散を求める情報</li>
                        <li>他人に不快感を与える表現を含む情報</li>
                    </ul>
                </li>
                <li>本サービスのネットワークまたはシステム等に過度な負荷をかける行為</li>
                <li>当方が提供するソフトウェアその他のシステムに対するリバースエンジニアリングその他の解析行為</li>
                <li>第三者に成りすます行為</li>
                <li>当方が事前に許諾しない本サービス上での宣伝、広告、勧誘、または営業行為</li>
                <li>本サービスの他の利用者の情報の収集</li>
                <li>当方、本サービスの他の利用者またはその他の第三者に不利益、損害、不快感を与える行為</li>
                <li>当方ウェブサイト上で掲載する本サービス利用に関するルール（<router-link to="/terms">https://speec.jp/terms</router-link>）に抵触する行為</li>
                <li>反社会的勢力等への利益供与</li>
                <li>面識のない異性との出会いを目的とした行為</li>
                <li>前各号の行為を直接または間接に惹起し、または容易にする行為</li>
                <li>前各号の行為を試みること</li>
                <li>その他、当方が不適切と判断する行為</li>
            </ol>
            
            <!-- 第5条 -->
            <h2 class="terms__content-title">第5条（本サービスの停止等）</h2>
            <p class="terms__content-text">当方は、以下のいずれかに該当する場合には、ユーザーに事前に通知することなく、本サービスの全部または一部の提供を停止または中断することができるものとします。</p>
            <ol class="terms__content-list">
                <li>本サービスに係るコンピューター・システムの点検または保守作業を緊急に行う場合</li>
                <li>コンピューター、通信回線等の障害、誤操作、過度なアクセスの集中、不正アクセス、ハッキング等により本サービスの運営ができなくなった場合</li>
                <li>地震、落雷、火災、風水害、停電、天災地変などの不可抗力により本サービスの運営ができなくなった場合</li>
                <li>その他、当方が停止または中断を必要と判断した場合</li>
            </ol>

            <!-- 第6条 -->
            <h2 class="terms__content-title">第6条（権利帰属）</h2>
            <ol class="terms__content-list">
                <li>当方ウェブサイト及び本サービスに関する知的財産権は全て当方または当方にライセンスを許諾している者に帰属しており、本規約に基づく本サービスの利用許諾は、当方ウェブサイトまたは本サービスに関する当方または当方にライセンスを許諾している者の知的財産権の使用許諾を意味するものではありません。</li>
                <li>ユーザーは、投稿データについて、自らが投稿その他送信することについての適法な権利を有していること、及び投稿データが第三者の権利を侵害していないことについて、当方に対し表明し、保証するものとします。</li>
                <li>ユーザーは、投稿データについて、当方に対し、世界的、非独占的、無償、サブライセンス可能かつ譲渡可能な使用、複製、配布、派生著作物の作成、表示及び実行に関するライセンスを付与します。また、他のユーザーに対しても、本サービスを利用してユーザーが投稿その他送信した投稿データの使用、複製、配布、派生著作物を作成、表示及び実行することについての非独占的なライセンスを付与します。</li>
                <li>ユーザーは、当方及び当方から権利を承継しまたは許諾された者に対して著作者人格権を行使しないことに同意するものとします。</li>
            </ol>

            <!-- 第7条 -->
            <h2 class="terms__content-title">第7条（本サービスの内容の変更、終了）</h2>
            <ol class="terms__content-list">
                <li>当方は、当方の都合により、本サービスの内容を変更し、または提供を終了することができます。</li>
                <li>当方が本サービスの提供を終了する場合、当方はユーザーに事前に通知するものとします。</li>
            </ol>

            <!-- 第8条 -->
            <h2 class="terms__content-title">第8条（保証の否認及び免責）</h2>
            <ol class="terms__content-list">
                <li>当方は、本サービスがユーザーの特定の目的に適合すること、期待する機能・商品的価値・正確性・有用性を有すること、ユーザーによる本サービスの利用がユーザーに適用のある法令または業界団体の内部規則等に適合すること、継続的に利用できること、及び不具合が生じないことについて、明示又は黙示を問わず何ら保証するものではありません。</li>
                <li>当方は、本サービスに関してユーザーが被った損害につき、過去【12ヶ月】間にユーザーが当方に支払った対価の金額を超えて賠償する責任を負わないものとし、また、付随的損害、間接損害、特別損害、将来の損害及び逸失利益にかかる損害については、賠償する責任を負わないものとします。</li>
                <li>本サービスまたは当方ウェブサイトに関連してユーザーと他のユーザーまたは第三者との間において生じた取引、連絡、紛争等については、ユーザーが自己の責任によって解決するものとします。</li>
            </ol>
            
            <!-- 第9条 -->
            <h2 class="terms__content-title">第9条（秘密保持）</h2>
            <p class="terms__content-text">ユーザーは、本サービスに関連して当方がユーザーに対して秘密に取扱うことを求めて開示した非公知の情報について、当方の事前の書面による承諾がある場合を除き、秘密に取扱うものとします。</p>

            <!-- 第10条 -->
            <h2 class="terms__content-title">第10条（利用者情報の取扱い）</h2>
            <ol class="terms__content-list">
                <li>当方によるユーザーの利用者情報の取扱いについては、別途当方プライバシーポリシー（<router-link to="/privacy-policy">http://speec.jp/privacy-plicy</router-link>）の定めによるものとし、ユーザーはこのプライバシーポリシーに従って当方がユーザーの利用者情報を取扱うことについて同意するものとします。</li>
                <li>当方は、ユーザーが当方に提供した情報、データ等を、個人を特定できない形での統計的な情報として、当方の裁量で、利用及び公開することができるものとし、ユーザーはこれに異議を唱えないものとします。</li>
            </ol>

            <!-- 第11条 -->
            <h2 class="terms__content-title">第11条（Googleアナリティクスの利用）</h2>
            <ol class="terms__content-list">
                <li>当方ウェブサイトでは、当方ウェブサイトのアクセス状況を把握するために、Google社の提供するサービスであるGoogleアナリティクスを利用しています。</li>
                <li>Googleアナリティクスはアクセス情報の収集のためにCookieを使用しています。このアクセス情報は匿名で収集されており、個人を特定するものではありません。詳細はGoogle アナリティクスの<a href="https://marketingplatform.google.com/about/analytics/terms/jp/" target="_blank">利用規約</a>および<a href="https://policies.google.com/privacy?hl=ja" target="_blank">プライバシーポリシー</a>をご覧ください。</li>
                <li>Googleアナリティクスによる情報収集を無効化したい場合は、Google社が提供する<a href="https://tools.google.com/dlpage/gaoptout?hl=ja" target="_blank">Googleアナリティクス オプトアウト アドオン</a>からオプトアウトの設定をすることで実施可能です。</li>
            </ol>

            <!-- 第12条 -->
            <h2 class="terms__content-title">第12条（本規約等の変更）</h2>
            <p class="terms__content-text">当方は、当方が必要と認めた場合は、本規約を変更できるものとします。本規約を変更する場合、変更後の本規約の施行時期及び内容を当方ウェブサイト上での掲示その他の適切な方法により周知し、またはユーザーに通知します。但し、法令上ユーザーの同意が必要となるような内容の変更の場合は、当方所定の方法でユーザーの同意を得るものとします。</p>
            
            <!-- 第13条 -->
            <h2 class="terms__content-title">第13条（連絡／通知）</h2>
            <ol class="terms__content-list">
                <li>本サービスに関する問い合わせその他ユーザーから当方に対する連絡または通知、及び本規約の変更に関する通知その他当方からユーザーに対する連絡または通知は、当方の定める方法で行うものとします。</li>
                <li>当方が問い合わせ事項に含まれるメールアドレスその他の連絡先に連絡または通知を行った場合、ユーザーは当該連絡または通知を受領したものとみなします。</li>
            </ol>

            <!-- 第14条 -->
            <h2 class="terms__content-title">第14条（サービス利用契約上の地位の譲渡等）</h2>
            <ol class="terms__content-list">
                <li>ユーザーは、当方の書面による事前の承諾なく、利用契約上の地位または本規約に基づく権利もしくは義務につき、第三者に対し、譲渡、移転、担保設定、その他の処分をすることはできません。</li>
                <li>当方は本サービスにかかる事業を他社に譲渡した場合には、当該事業譲渡に伴い利用契約上の地位、本規約に基づく権利及び義務並びにその他の顧客情報を当該事業譲渡の譲受人に譲渡することができるものとし、ユーザーは、かかる譲渡につき本項において予め同意したものとします。なお、本項に定める事業譲渡には、通常の事業譲渡のみならず、会社分割その他事業が移転するあらゆる場合を含むものとします。</li>
            </ol>

            <!-- 第15条 -->
            <h2 class="terms__content-title">第15条（分離可能性）</h2>
            <p class="terms__content-text">本規約のいずれかの条項またはその一部が、消費者契約法その他の法令等により無効または執行不能と判断された場合であっても、本規約の残りの規定及び一部が無効または執行不能と判断された規定の残りの部分は、継続して完全に効力を有するものとします。</p>

            <!-- 第16条 -->
            <h2 class="terms__content-title">第16条（準拠法及び管轄裁判所）</h2>
            <ol class="terms__content-list">
                <li>本規約及びサービス利用契約の準拠法は日本法とします。</li>
                <li>本規約またはサービス利用契約に起因し、または関連する一切の紛争については、東京地方裁判所を第一審の専属的合意管轄裁判所とします。</li>
            </ol>
            <p class="terms__content-created">【2021年9月18日制定】</p>
            <p class="terms__content-end">以上</p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Terms',
    data() {
        return {
            title: '利用規約',
        }
    },
}
</script>

<style lang="scss" scoped>
@import '../assets/scss/mixin';
@import '../assets/scss/var';

a { color: $primary; }
ul { list-style-type: disc; }
ol { list-style-type: numeric; }

.terms {
    width: 100%;
    max-width: 1080px;
    padding: 108px 20px 40px 20px;
    margin: 0 auto;

    @include mq(){
        padding: 148px 40px;
    }


    &__title {
        margin: 0 0 20px 0;
        text-align: left;
        font-size: 2.4rem;
        font-weight: bold;

        @include mq(){
            font-size: 3.2rem;
        }
    }

    &__header {
        margin: 0 0 20px 0;
    }

    &__content {
        &-title {
            margin: 40px 0 10px 0;
            font-size: 1.6rem;
            font-weight: bold;

            @include mq(){
                font-size: 2rem;
            }

            &:first-child {
                margin: 0 0 10px 0;
            }
        }

        &-text {
            margin: 0 0 10px 0;
        }

        &-list {
            margin: 10px 0;
            padding: 0 0 0 40px;

            li {

                &:last-child {
                    margin: 0;
                }
            }
        }

        &-datalist {
            display: flex;
            
            dt {
                margin: 0 10px 0 0;

            }
        }

        //制定日
        &-created {
            margin: 20px 0;
            text-align: right;
        }

        //改定日
        &-updated {
            margin: 0 0 20px 0;
            text-align: right;
        }

        //EOL
        &-end {
            text-align: right;
        }
    }
}
</style>
<template>
    <div class="contact">
        <h1 class="contact__title">{{ title }}</h1>
        <div class="contact__header">
            <p></p>
        </div> 
        <div class="contact__content">
            <iframe src="https://docs.google.com/forms/d/e/1FAIpQLScMCJ6U9KqTvuyvom1VUN2lr8uzATn5Rxqznmw3-Ekham62xw/viewform?embedded=true" width="1080" height="1030" frameborder="0" marginheight="0" marginwidth="0">読み込んでいます…</iframe>
        </div>
</div>
</template>

<script>
export default {
    name: 'Contact',
    data() {
        return {
            title: 'お問い合わせ',
        }
    },
}
</script>

<style lang="scss" scoped>
@import '../assets/scss/mixin';
@import '../assets/scss/var';

.contact {
    width: 100%;
    max-width: 1080px;
    padding: 108px 20px 40px 20px;
    margin: 0 auto;

    @include mq(){
        padding: 148px 40px;
    }

    &__title {
        margin: 0 0 20px 0;
        text-align: left;
        font-size: 2.4rem;
        font-weight: bold;

        @include mq(){
            font-size: 3.2rem;
        }
    }

    &__header {
        margin: 0 0 20px 0;
    }

    &__content {
        position: relative;
        width: 100%;
        height: 1030px;
        overflow-x: hidden !important;
        overflow-y: auto;
        
        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            max-width: 100%;
        }
    }
}
</style>
<template>
    <div class="home">
        <!-- キービジュアル -->
        <section class="keyvisual">
            <div class="keyvisual__inner">
                <h1 class="keyvisual__text">SPEECなら、<br class="sp">必要なときに、必要なだけ。<br>見積書、発注書、請求書等の書類を<br class="sp">簡単作成。</h1>
            </div>
        </section>

        <!-- SPEECとは？ -->
        <section class="about">
            <div class="about__inner">
                <h2 class="about__title">SPEEC<span>(スピーク)</span>とは？</h2>
                <div class="about__content">
                    <p class="about__content-text">SPEECはユーザー登録不要でご利用いただける無料の書類作成サービスです。<br>ビジネスに必要な書類をWEBで簡単に作成することができます。</p>
                    <h3 class="about__content-title">作成書類一覧</h3>
                    <ul class="about__content-document">
                        <li><i class="fas fa-file-alt"></i>御見積書</li>
                        <li><i class="fas fa-file-alt"></i>発注書</li>
                        <li><i class="fas fa-file-alt"></i>発注請書</li>
                        <li><i class="fas fa-file-alt"></i>納品書</li>
                        <li><i class="fas fa-file-alt"></i>検収書</li>
                        <li><i class="fas fa-file-alt"></i>請求書</li>
                        <li><i class="fas fa-file-alt"></i>領収書</li>
                    </ul>
                    <ul class="about__content-list">
                        <li>上記書類を1つのPDFにまとめてダウンロードいただけます。必要のない書類はツールで編集することができます。</li>
                    </ul>
                    <div class="about__content-action">
                        <router-link to="/document" class="about__content-btn btn btn__red">SPEECで書類を作成する</router-link>
                    </div>
                </div>
            </div>
        </section>

        <!-- 料金について -->
        <section class="price">
            <div class="price__inner">
                <h2 class="price__title">料金について</h2>
                <div class="price__content">
                    <p class="price__content-text">書類の作成からダウンロードまで無料でご利用いただけます。<br>必要なときに必要なだけご活用ください。</p>
                    <dl class="price__content-datalist">
                        <dt>書類一式ダウンロード</dt>
                        <dd>0円<span>(税込)</span></dd>
                    </dl>
                </div>
            </div>
        </section>

        <!-- ご利用の流れ -->
        <section class="flow">
            <div class="flow__inner">
                <h2 class="flow__title">ご利用の流れ</h2>
                <div class="flow__content">
                    <p class="flow__content-text">SPEECに複雑な操作は必要ありません。3つのステップで簡単に書類を作成することができます。<br>（入力されたデータは保存されませんので、ご留意ください。）</p>
                    <ul class="flow__content-list">
                        <li>
                            <span class="num">1</span>
                            <figure>
                                <img src="/assets/images/home_flow1.png" alt="">
                            </figure>
                            <p>プレビューを見ながら<br>必要事項を入力</p>
                        </li>
                        <li>
                            <span class="num">2</span>
                            <figure>
                                <img src="/assets/images/home_flow2.png" alt="">
                            </figure>
                            <p>入力完了後<br>書類をダウンロード</p>
                        </li>
                        <li>
                            <span class="num">3</span>
                            <figure>
                                <img src="/assets/images/home_flow3.png" alt="">
                            </figure>
                            <p>ダウンロードした書類を<br>ビジネスに活用</p>
                        </li>
                    </ul>
                </div>
            </div>
        </section>

        <!-- フィードバックについて -->
        <section class="request">
            <div class="request__inner">
                <h2 class="request__title">要望＆改善</h2>
                <div class="request__content">
                    <p class="request__content-text">SPEECをより良いサービスにするために、ご要望や改善・追加して欲しい機能等があれば気軽にお問い合わせください。</p>
                    <div class="request__content-action">
                        <router-link to="/contact" class="request__content-btn btn btn__red-btn">お問い合わせ</router-link>
                    </div>
                </div>
                <figure class="request__image">
                    <img src="/assets/images/home_request.jpg" alt="">
                </figure>
            </div>
        </section>
        
        <!-- SPEECで書類を作成する -->
        <section class="create">
            <div class="create__inner">
                <div class="create__content">
                    <div class="create__content-text">さあ、はじめましょう！</div>
                    <div class="create__content-action">
                        <router-link to="/document" class="create__content-btn">SPEECで書類を作成する</router-link>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
export default {
    name: 'Home',
    mounted() {
        document.body.style.overflowY = 'auto';
    },
}
</script>

<style lang="scss" scoped>
@import '../assets/scss/mixin';
@import '../assets/scss/var';

.home {
    width: 100%;
    
    //キービジュアル
    .keyvisual {
        display: flex;
        align-items: center;
        width: 100%;
        height: 400px;
        background-color: $primary;
        background-image: url(/assets/images/home_keyvisual.png), $gradient;
        background-size: 300px, cover;
        background-position: right -100px bottom -100px, center;
        background-repeat: no-repeat;

        @include mq(){
            background-size: 400px, cover;
            background-position: right -100px bottom -100px, center;
        }

        @include mq(xl){
            height: 500px;
            background-size: 500px, cover;
            background-position: right 10% bottom -100px, center;
        }

        @include mq(xxl){
            background-position: right 20% bottom -150px, center;
        }

        &__inner {
            width: 92%;
            height: auto;
            max-width: 1280px;
            margin: 0 auto;
        }

        &__text {
            font-size: 2.4rem;
            color: $white;

            @include mq(){
                font-size: 3.2rem;
            }
        }
    }

    //SPEECとは?
    .about {
        width: 100%;

        &__inner {
            width: 100%;
            height: auto;
            max-width: 1280px;
            padding: 60px 20px;
            margin: 0 auto;

            @include mq(){
                padding: 80px 40px;
            }
        }

        &__title {
            margin: 0 0 20px 0;
            font-size: 2.4rem;
            font-weight: bold;

            @include mq(){
                margin: 0 0 40px 0;
                font-size: 3.2rem;
            }

            span {
                margin: 0 4px;
                font-size: 2.4rem;

                @include mq(){
                    font-size: 3.2rem;
                }
            }
        }

        &__content {
            &-title {
                margin: 0 0 10px 0;
                font-size: 2rem;
                font-weight: bold;
            }

            &-text {
                margin: 0 0 20px 0;
                font-size: 1.6rem;

                @include mq(){
                    font-size: 2rem;
                }
            }

            &-list {
                margin: 0 0 40px 0;
                list-style-type: none;

                li {
                    position: relative;
                    padding: 0 0 0 20px;
                    font-size: 1.4rem;
                    font-weight: bold;
                    color: $gray-dark;

                    &:before {
                        content: '※';
                        position: absolute;
                        top: 0;
                        left: 0;
                    }

                    @include mq(){
                        font-size: 1.6rem;
                    }
                }
            }

            &-document {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                margin: 0 0 10px 0;

                @include mq(){
                    justify-content: space-between;
                }

                li {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: calc(96% / 2);
                    padding: 10px 20px;
                    margin: 0 0 3% 0;
                    letter-spacing: 0.25rem;
                    font-weight: bold;
                    text-align: center;
                    color: $white;
                    background-color: $primary-dark;

                    @include mq(){
                        width: calc(97% / 3);
                        margin: 0 0 1.5% 0;
                    }

                    @include mq(xl){
                        width: calc(93% / 7);
                        margin: 0;

                        &:last-child {
                            margin: 0;
                        }
                    }

                    i {
                        margin: 0 6px 0 0;
                    }

                    &:last-child {
                        margin: 0;
                    }
                }
            }
            
            &-action {
                display: flex;
                justify-content: center;

                @include mq(){
                    justify-content: flex-end;
                }
            }

            &-btn {
                font-size: 1.6rem;

                @include mq(){
                    font-size: 2rem;
                }
            }
        }
    }

    //料金について
    .price {
        width: 100%;

        &__inner {
            position: relative;
            width: 100%;
            height: auto;
            max-width: 1280px;
            padding: 60px 20px;
            margin: 0 auto;

            @include mq(){
                padding: 80px 40px;
                background-image: url(/assets/images/home_price1.png);
                background-repeat: no-repeat;
                background-size: 320px;
                background-position: right bottom 5px;
            }

            @include mq(xl){
                background-size: 320px;
            }

            &:before {
                content: '';
                position: absolute;
                top: -5px;
                left: 0;
                width: 80px;
                height: 10px;
                background-color: $primary;
            }
        }

        &__title {
            margin: 0 0 20px 0;
            font-size: 2.4rem;
            font-weight: bold;

            @include mq(){
                margin: 0 0 40px 0;
                font-size: 3.2rem;
            }
        }

        &__content {
            &-text {
                margin: 0 0 20px 0;

                @include mq(){
                    margin: 0 0 40px 0;
                    font-size: 2rem;
                }

                &:last-child {
                    margin: 0;
                }

                span {
                    font-size: 1rem;

                    @include mq(){
                        font-size: 1.2rem;
                    }
                }
            }

            &-datalist {
                display: flex;
                align-items: center;
                border-bottom: 1px solid $gray;

                @include mq(){
                    display: inline-flex;
                }

                dt {
                    padding: 10px;
                    line-height: 1.4;
                    font-weight: bold;
                    text-align: center;
                    vertical-align: bottom;
                    color: $white;
                    background-color: $red;

                    @include mq(){
                        padding: 10px 20px;
                    }

                }

                dd {
                    padding: 10px;
                    line-height: 1.4;
                    font-size: 2.4rem;
                    font-weight: bold;
                    text-align: right;
                    vertical-align: bottom;

                    @include mq(){
                        padding: 10px 20px;
                        font-size: 3.2rem;
                    }
                    
                    span {
                        margin: 0 0 0 10px;
                        font-size: 1.2rem;
                        font-weight: normal;

                        @include mq(){
                            font-size: 1.6rem;
                        }
                    }

                }
            }
        }
    }

    //ご利用の流れ
    .flow {
        width: 100%;

        &__inner {
            position: relative;
            width: 100%;
            height: auto;
            max-width: 1280px;
            padding: 60px 20px;
            margin: 0 auto;

            @include mq(){
                padding: 80px 40px;
            }

            &:before {
                content: '';
                position: absolute;
                top: -5px;
                right: 0;
                width: 80px;
                height: 10px;
                background-color: $red;
            }
        }

        &__title {
            margin: 0 0 20px 0;
            font-size: 2.4rem;
            font-weight: bold;

            @include mq(){
                margin: 0 0 40px 0;
                font-size: 3.2rem;
            }
        }

        &__content {
            &-text {
                margin: 0 0 20px 0;

                @include mq(){
                    margin: 0 0 40px 0;
                    font-size: 2rem;
                }
                
                &:last-child {
                    margin: 0;
                }
            }

            &-list {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                margin: 0 0 40px 0;

                li {
                    position: relative;
                    width: 100%;
                    margin: 0 0 20px 0;
                    background-color: $gray-light;

                    &:last-child {
                        margin: 0;
                    }

                    @include mq(){
                        width: 32%;
                        margin: 0;
                    }

                    .num {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 30px;
                        height: 30px;
                        line-height: 30px;
                        font-size: 1.6rem;
                        font-weight: bold;
                        text-align: center;
                        color: $white;
                        background-color: $red;
                    }

                    figure {
                        width: 100%;
                        max-width: 280px;
                        height: auto;
                        padding: 20px 0 0 0;
                        margin: 0 auto;
                        overflow: hidden;

                        img {
                            width: 100%;
                        }
                    }

                    p {
                        line-height: 1.5;
                        padding: 20px 0;
                        font-weight: bold;
                        text-align: center;
                        color: $white;
                        background-color: $primary-dark;
                    }
                }
            }
        }
    }

    //要望&改善
    .request {
        width: 100%;

        &__inner {
            position: relative;
            display: flex;
            align-items: flex-start;
            flex-direction: column;
            flex-wrap: wrap;
            justify-content: space-between;
            width: 100%;
            height: auto;
            max-width: 1280px;
            padding: 60px 20px;
            margin: 0 auto;

            @include mq(){
                flex-direction: row;
                padding: 80px 40px;
            }

            &:before {
                content: '';
                position: absolute;
                top: -5px;
                left: 0;
                width: 80px;
                height: 10px;
                background-color: $gray;
            }
        }

        &__title {
            width: 100%;
            margin: 0 0 20px 0;
            font-size: 2.4rem;
            font-weight: bold;
            order: 1;

            @include mq(){
                margin: 0 0 40px 0;
                font-size: 3.2rem;
            }
        }

        &__content {
            order: 3;
            width: 100%;

            @include mq(){
                order: 2;
                width: 66%;
            }
            
            &-text {
                margin: 0 0 40px 0;
                font-size: 1.6rem;

                @include mq(){
                    font-size: 2rem;
                }

                &:last-child {
                    margin: 0;
                }
            }

            &-action {
                display: flex;
                justify-content: center;

                @include mq(){
                    justify-content: flex-start;
                }
            }

            &-btn {
                font-size: 1.6rem;
                color: $white;
                background-color: $red;

                @include mq(){
                    font-size: 2rem;
                }
            }
        }

        &__image {
            order: 2;
            width: 100%;
            max-width: 320px;
            margin: 20px auto 40px;
            text-align: center;

            @include mq(){
                order: 3;
                width: 30%;
                margin: -40px 0 0 0;
            }
        }
    }

    //SPEECで書類を作成する
    .create {
        width: 100%;
        background-image: $gradient;

        &__inner {
            position: relative;
            width: 100%;
            height: auto;
            max-width: 1280px;
            padding: 60px 20px;
            margin: 0 auto;

            @include mq(){
                padding: 80px 40px;
            }

            &:before {
                content: '';
                position: absolute;
                top: -5px;
                right: 0;
                width: 80px;
                height: 10px;
                background-color: $red;
            }
        }

        &__content {
            &-text {
                margin: 0 0 20px 0;
                text-align: center;
                font-size: 1.6rem;
                font-weight: bold;
                color: $white;

                @include mq(){
                    font-size: 2rem;
                }

                &:before {  
                   content: "＼";
                    margin: 0 5px 0 0;
                }

                &:after {
                    content: '／';
                    margin: 0 0 0 5px;
                }
            }
            &-action {
                display: flex;
                justify-content: center;
            }

            &-btn {
                padding: 10px 40px;
                font-size: 2rem;
                font-weight: bold;
                text-align: center;
                text-decoration: none;
                border-radius: 50px;
                color: $primary;
                background-color: $white;
                box-shadow: $box-shadow;
                transition: .3s;

                @include mq(){
                    padding: 20px 80px;
                    letter-spacing: 0.5rem;
                    font-size: 3.2rem;
                }
                &:hover {
                    opacity: .7;
                }
            }
        }
    }
}
</style>
<template>
    <header id="header" class="header">
        <div class="header__inner">
            <nav class="header__nav">
                <router-link to="/"><i class="fas fa-home"></i>HOME</router-link>
                <router-link to="/document"><i class="fas fa-file"></i>書類作成</router-link>
            </nav>
            <div class="header__logo">
                <router-link to="/" class="header__logo"><img src="/assets/images/logo.png" alt="SPEEC"></router-link>
            </div>
        </div>
    </header>
</template>

<style lang="scss">
@charset 'utf-8';

@import '../assets/scss/mixin';
@import '../assets/scss/var';

.header {
    position: fixed;
    top: 0;
    left: 0;
    width: auto;
    height: auto;
    z-index: 1;

    &__logo {
        position: fixed;
        top: 20px;
        right: 20px;

        img {
            display: block;
            width: auto;
            height: 16px;
        }
    }
    
    &__nav {
        display: flex;
        justify-content: flex-start;

        a {
            display: flex;
            align-items: center;
            justify-content: center;
            width: auto;
            padding: 20px;
            font-weight: bold;
            color: $black;
            text-decoration: none;
            color: $white;
            background-color: $primary;

            i {
                margin: 0 6px 0 0;
                font-size: 1.1rem;

                @include mq(){
                    font-size: 1.4rem;
                }
            }

            &.router-link-exact-active {
                background-color: $primary-dark;
            }
        }
    }
}
</style>